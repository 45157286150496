import type { ReactNode } from "react";
import { Fragment } from "react";

interface Props {
  number: number;
  children: ReactNode;
}

const SkeletonList = ({ number = 1, children }: Props) => (
  <>
    {Array.from({ length: number }).map((_, i) => (
      <Fragment key={"skeleton-" + i}>{children}</Fragment>
    ))}
  </>
);

export { SkeletonList };
