import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

import { helpers } from "@styles";

export const dropdownButton = css`
  flex-direction: row;
  justify-content: space-between;
`;

export const color = (theme: Theme) => css`
  color: ${theme.system.textLabelEmphasis};
  background-color: ${theme.system.backgroundBase};
  border-color: ${theme.system.borderBase};

  &:hover {
    background-color: ${theme.system.backgroundMuted};
    border-color: ${theme.system.borderHover};
    color: ${theme.system.textLabelEmphasis};
  }

  &:active {
    background-color: ${theme.system.backgroundMutedHover};
    border-color: ${theme.system.borderActive};
    color: ${theme.system.textLabelEmphasis};
  }
`;

export const isText = css`
  border-color: transparent;

  &:hover,
  &:active {
    border-color: transparent;
  }
`;

export const isCentered = {
  button: css`
    justify-content: center;
  `,
  contentWrapper: css`
    text-align: revert; // text is automatically centered with flex: 1 on the content wrapper. Reverts text-align: left rule added below
  `,
};

export const dropdownButtonContentWrapper = css`
  ${helpers.ellipsesOverflowHelper}
  flex: 1;
  text-align: left;
`;

export const text = css`
  ${helpers.ellipsesOverflowHelper}
  font-weight: ${typography.weightBase};
  max-width: 100%;
`;

export const prefix = css`
  font-weight: ${typography.weightSemiBold};
  margin-right: 0.25rem;
`;
