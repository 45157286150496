import { css } from "@emotion/react";
import { typography } from "aviary-tokens";

import { helpers } from "@styles";

export const dropdownButton = css`
  flex-direction: row;
  justify-content: space-between;
`;

export const isCentered = {
  button: css`
    justify-content: center;
  `,
  contentWrapper: css`
    text-align: revert; // text is automatically centered with flex: 1 on the content wrapper. Reverts text-align: left rule added below
  `,
};

export const dropdownButtonContentWrapper = css`
  ${helpers.ellipsesOverflowHelper}
  flex: 1;
  text-align: left;
`;

export const text = css`
  ${helpers.ellipsesOverflowHelper}
  font-weight: ${typography.weightBase};
  max-width: 100%;
`;

export const prefix = css`
  font-weight: ${typography.weightBold};
  margin-right: 0.25rem;
`;
